import React                          from 'react';
import { useIntl }                    from 'gatsby-plugin-intl';
import './Homepage.scss';
import phone                          from '../../images/screenshot.png';
import HomepageBlock                  from './HomepageBlock/HomepageBlock';
import { neutralBgColor, warning200 } from '../../utils/colors';
import richieste from '../../images/richieste.png';
import bachecaCommerciale from '../../images/bacheca-commerciale.png';
import profilo from '../../images/profilo.png';
import phones                         from '../../images/doppi-telefoni.png';
import phonesMobile    from '../../images/doppi-telefoni.png';
import serenaFregonese from '../../images/serena-fregonese.png';
import alessandroMoro  from '../../images/alessandro-moro.png';
import { navigate }    from 'gatsby-link';
import unipol          from '../../images/insurance_companies/unipol-sai.png';
import cattolica    from '../../images/insurance_companies/cattolica-colori.png';
import realemutua   from '../../images/insurance_companies/reale-mutua.jpg';
import uca          from '../../images/insurance_companies/uca.png';
import arag         from '../../images/insurance_companies/arag.jpg';
import aig          from '../../images/insurance_companies/aig.png';
import metlife      from '../../images/insurance_companies/metlife.jpg';
import genialloyd   from '../../images/insurance_companies/genialloyd.png';
import prima        from '../../images/insurance_companies/prima.png';
import europ        from '../../images/insurance_companies/europ.png';
import adriatic     from '../../images/insurance_companies/adriatic.png';
import { Products } from "../Products/Products"

const HomepageAgents = () => {
  const intl = useIntl();
  return (
    <div className="homepage">
      <HomepageBlock customId={'homepage-agents-block-1'}
                     mode={'cta'}
                     title={intl.formatMessage({ id: 'homepage-agents.main-hero' })}
                     description={[intl.formatMessage({ id: 'homepage-agents.main-hero-subtitle' })]}
                     cta={{
                       text: intl.formatMessage({ id: 'homepage-agents.main-hero-cta' }),
                       action: () => navigate('/contattaci'),
                     }}
                     images={[phone]}
                     isAgent={true}
                     hasBg={false}/>
      <Products />
      <HomepageBlock customId={'homepage-agents-block-2'}
                     mode={'default'}
                     images={[richieste]}
                     headline={intl.formatMessage({ id: 'homepage-agents.second-hero-headline' })}
                     title={intl.formatMessage({ id: 'homepage-agents.second-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage-agents.second-hero-subtitle' })]}
                     bulletList={[intl.formatMessage({ id: 'homepage-agents.second-hero-li-1' }),
                       intl.formatMessage({ id: 'homepage-agents.second-hero-li-2' }),
                       intl.formatMessage({ id: 'homepage-agents.second-hero-li-3' })]}
                     hasBg={false}/>
      <HomepageBlock customId={'homepage-agents-block-3'}
                     mode={'reverse'}
                     images={[bachecaCommerciale]}
                     headline={intl.formatMessage({ id: 'homepage-agents.third-hero-headline' })}
                     title={intl.formatMessage({ id: 'homepage-agents.third-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage-agents.third-hero-subtitle' })]}
                     bulletList={[intl.formatMessage({ id: 'homepage-agents.third-hero-li-1' }),
                       intl.formatMessage({ id: 'homepage-agents.third-hero-li-2' }),
                       intl.formatMessage({ id: 'homepage-agents.third-hero-li-3' })]}
                     hasBg={neutralBgColor}/>
      <HomepageBlock customId={'homepage-agents-block-4'}
                     mode={'default'}
                     images={[profilo]}
                     headline={intl.formatMessage({ id: 'homepage-agents.fourth-hero-headline' })}
                     title={intl.formatMessage({ id: 'homepage-agents.fourth-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage-agents.fourth-hero-subtitle' })]}
                     bulletList={[intl.formatMessage({ id: 'homepage-agents.fourth-hero-li-1' }),
                       intl.formatMessage({ id: 'homepage-agents.fourth-hero-li-2' }),
                       intl.formatMessage({ id: 'homepage-agents.fourth-hero-li-3' })]}
                     hasBg={false}/>
      <HomepageBlock customId={'homepage-agents-block-5'}
                     mode={'big'}
                     images={[phones, phonesMobile]}
                     headline={intl.formatMessage({ id: 'homepage-agents.fifth-hero-headline' })}
                     title={intl.formatMessage({ id: 'homepage-agents.fifth-hero-title' })}
                     descriptions={[
                       {
                         title: intl.formatMessage({ id: 'homepage-agents.big-block-descr-1-title' }),
                         text: intl.formatMessage({ id: 'homepage-agents.big-block-descr-1-text' }),
                       },
                       {
                         title: intl.formatMessage({ id: 'homepage-agents.big-block-descr-2-title' }),
                         text: intl.formatMessage({ id: 'homepage-agents.big-block-descr-2-text' }),
                       },
                       {
                         title: intl.formatMessage({ id: 'homepage-agents.big-block-descr-3-title' }),
                         text: intl.formatMessage({ id: 'homepage-agents.big-block-descr-3-text' }),
                       },
                       {
                         title: intl.formatMessage({ id: 'homepage-agents.big-block-descr-4-title' }),
                         text: intl.formatMessage({ id: 'homepage-agents.big-block-descr-4-text' }),
                       },
                     ]}
                     hasBg={neutralBgColor}/>
      <HomepageBlock mode={'cta-handshakes'}
                      title={intl.formatMessage({ id: 'homepage-agents.sixth-hero-title' })}
                      description={[intl.formatMessage({ id: 'homepage-agents.sixth-hero-subtitle' })]}
                      bulletList={[{
                        title: intl.formatMessage({ id: 'homepage-agents.sixth-hero-li-1' }),
                        text: intl.formatMessage({ id: 'homepage-agents.sixth-hero-li-1-text' }),
                      }, {
                        title: intl.formatMessage({ id: 'homepage-agents.sixth-hero-li-2' }),
                        text: intl.formatMessage({ id: 'homepage-agents.sixth-hero-li-2-text' }),
                      }, {
                        title: intl.formatMessage({ id: 'homepage-agents.sixth-hero-li-3' }),
                        text: intl.formatMessage({ id: 'homepage-agents.sixth-hero-li-3-text' }),
                      }]}
                      cta={{
                        text: intl.formatMessage({ id: 'homepage-agents.sixth-hero-cta' }),
                        action: () => navigate('/contattaci'),
                      }}
                      hasBg={warning200}/>
      <HomepageBlock customId={'homepage-agents-block-6'}
                     mode={'partners'}
                     title={intl.formatMessage({ id: 'homepage.seventh-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage.seventh-hero-subtitle' })]}
                     images={[unipol, cattolica, realemutua, uca, arag, aig, metlife, genialloyd, prima, europ, adriatic]}
                     hasBg={false}/>
      <HomepageBlock customId={'homepage-agents-block-7'}
                     mode={'quotes'}
                     title={intl.formatMessage({ id: 'homepage.eight-hero-title' })}
                     description={[intl.formatMessage({ id: 'homepage.eight-hero-subtitle' })]}
                     quotes={[
                       {
                         text: 'Processo di registrazione rapido e veloce. \n Il mio consulente ha capito subito le mie esigenze e mi ha fatto risparmiare oltre 100€',
                         author: {
                           name: 'Serena Fregonese',
                           role: 'Commessa',
                           profileImage: serenaFregonese,
                         },
                       },
                       {
                         text: 'Finalmente tutte le mie polizze in un’unica app!\n Ora posso gestire le mie polizze in totale libertà e risparmiare tempo!',
                         author: {
                           name: 'Alessandro Moro',
                           role: 'Imprenditore',
                           profileImage: alessandroMoro,
                         },
                       },
                     ]}
                     hasBg={false}/>
    </div>
  );
};
export default HomepageAgents;
